import { CompletionContext } from '@codemirror/autocomplete';
import { EditorView } from '@codemirror/view';
import { tags as t } from '@lezer/highlight';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { langs } from '@uiw/codemirror-extensions-langs';
import { createTheme, CreateThemeOptions } from '@uiw/codemirror-themes';
import CodeMirror from '@uiw/react-codemirror';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MangoQuery } from 'rxdb';

import { useFind } from '../../../../shared/foreground/databaseHooks';
import { PromptResponseAction, PromptReturnType } from '../../../../shared/foreground/ghostreader';
import { mergePrompts, useDefaultPrompts } from '../../../../shared/foreground/ghostreaderLegacy/hooks';
import { canUseOpenAIModel } from '../../../../shared/foreground/ghostreaderLegacy/inference';
import { Prompt, PromptModel, PromptType } from '../../../../shared/foreground/ghostreaderLegacy/types';
import { globalState } from '../../../../shared/foreground/models';
import { updatePromptLegacy } from '../../../../shared/foreground/stateUpdaters/clientStateUpdaters/readerSettings';
import { createCancelableToast, createToast } from '../../../../shared/foreground/toasts.platform';
import copyTextToClipboard from '../../../../shared/foreground/utils/copyTextToClipboard';
import getServerBaseUrl from '../../../../shared/utils/getServerBaseUrl.platform';
import requestWithAuth from '../../../../shared/utils/requestWithAuth.platformIncludingExtension';
import Button from '../Button';
import { Dialog } from '../Dialog';
import { Dropdown, DropdownOption, DropdownOptionType } from '../Dropdown/Dropdown';
import GhostreaderPromptsOptionsDropdown from '../Dropdown/GhostreaderPromptsOptionsDropdown';
import SelectIcon from '../icons/20StrokeSelect';
import Tooltip from '../Tooltip';
import { AccountSettingsPage } from './AccountSettingsPage';
import styles from './GhostreaderPromptsPageLegacy.module.css';

type PromptDetailsHandle = {
  reset: () => void;
};

type PromptDetailsProps = {
  prompt: Prompt;
  isEditable: boolean;
  onCancel: () => void;
  onEdit: (promptType: string) => void;
  onRender: (prompt: Prompt) => void;
  onUpdate: (prompt: Prompt) => void;
};

export const defaultSettingsBasicLight: CreateThemeOptions['settings'] = {
  background: 'var(--background-primary)',
  caret: '#3b4252',
  fontFamily: 'var(--font-family-monospace)',
  foreground: 'var(--text-primary)',
  gutterActiveForeground: 'var(--text-primary)',
  selection: 'var(--background-secondary)',
  selectionMatch: 'var(--background-secondary)',
  gutterBackground: '#eceff4',
  gutterForeground: '#2e3440',
  gutterBorder: 'none',
  lineHighlight: '#02255f11',
};

const readwiseTheme = (options?: Partial<CreateThemeOptions>) => {
  const { theme = 'light', settings = {}, styles = [] } = options ?? {};
  return createTheme({
    theme,
    settings: {
      ...defaultSettingsBasicLight,
      ...settings,
    },
    styles: [
      { tag: t.keyword, color: 'var(--purple-40)', fontWeight: 'var(--font-weight-medium)' },
      {
        tag: [t.name, t.deleted, t.character, t.propertyName, t.macroName],
        color: 'var(--purple-40)',
        fontWeight: 'var(--font-weight-medium)',
      },
      { tag: [t.variableName], color: 'var(--purple-40)', fontWeight: 'var(--font-weight-medium)' },
      { tag: [t.function(t.variableName)], color: 'var(--purple-40)' },
      { tag: [t.labelName], color: 'var(--purple-40)' },
      {
        tag: [t.color, t.constant(t.name), t.standard(t.name)],
        color: 'var(--purple-40)',
      },
      { tag: [t.definition(t.name), t.separator], color: 'var(--purple-40)' },
      { tag: [t.brace], color: 'var(--purple-40)' },
      {
        tag: [t.annotation],
        color: 'var(--purple-40)',
      },
      {
        tag: [t.number, t.changed, t.annotation, t.modifier, t.self, t.namespace],
        fontWeight: 'var(--font-weight-bold)',
        color: 'var(--purple-40)',
      },
      {
        tag: [t.typeName, t.className],
        color: 'var(--purple-40)',
      },
      {
        tag: [t.operator, t.operatorKeyword],
        color: 'rgba(140,60,162,0.5)',
        fontWeight: 'var(--font-weight-bold)',
      },
      {
        tag: [t.tagName],
        fontWeight: 'var(--font-weight-bold)',
        color: 'rgba(140,60,162,0.5)',
      },
      {
        tag: [t.squareBracket],
        color: 'var(--purple-40)',
      },
      {
        tag: [t.angleBracket],
        color: 'var(--purple-40)',
      },
      {
        tag: [t.attributeName],
        color: 'var(--purple-40)',
      },
      {
        tag: [t.regexp],
        color: 'var(--purple-40)',
      },
      {
        tag: [t.quote],
        color: 'var(--purple-40)',
      },
      { tag: [t.string], color: 'var(--purple-40)' },
      {
        tag: t.link,
        color: 'var(--purple-40)',
        textDecoration: 'underline',
        textUnderlinePosition: 'under',
      },
      {
        tag: [t.url, t.escape, t.special(t.string)],
        color: 'var(--purple-40)',
      },
      { tag: [t.meta], color: 'var(--purple-40)' },
      { tag: [t.comment], color: 'var(--purple-40)', fontStyle: 'italic' },
      { tag: t.strong, fontWeight: 'var(--font-weight-medium)', color: 'var(--purple-40)' },
      { tag: t.emphasis, fontStyle: 'italic', color: 'var(--purple-40)' },
      { tag: t.strikethrough, textDecoration: 'line-through' },
      { tag: t.heading, fontWeight: 'var(--font-weight-medium)', color: 'var(--purple-40)' },
      { tag: t.special(t.heading1), fontWeight: 'var(--font-weight-medium)', color: 'var(--purple-40)' },
      { tag: t.heading1, fontWeight: 'var(--font-weight-medium)', color: 'var(--purple-40)' },
      {
        tag: [t.heading2, t.heading3, t.heading4],
        fontWeight: 'var(--font-weight-medium)',
        color: 'var(--purple-40)',
      },
      {
        tag: [t.heading5, t.heading6],
        color: 'var(--purple-40)',
      },
      { tag: [t.atom, t.bool, t.special(t.variableName)], color: 'var(--purple-40)' },
      {
        tag: [t.processingInstruction, t.inserted],
        color: 'var(--purple-40)',
      },
      {
        tag: [t.contentSeparator],
        color: 'var(--purple-40)',
      },
      { tag: t.invalid, color: 'var(--purple-40)', borderBottom: '1px dotted #d30102' },
      ...styles,
    ],
  });
};

function promptId(prompt: Prompt): string {
  return `${prompt.scope}-${prompt.type}`;
}

function completions(context: CompletionContext) {
  const word = context.matchBefore(/\w*/);
  if (!word || (word.from === word.to && !context.explicit)) {
    return null;
  }

  return {
    from: word.from,
    options: [
      // control structures
      { label: 'if', type: 'keyword', apply: '{% if -%}' },
      { label: 'elif', type: 'keyword', apply: '{% elif -%}' },
      { label: 'endif', type: 'keyword', apply: '{% endif -%}' },
      { label: 'for', type: 'keyword', apply: '{% for -%}' },
      { label: 'endfor', type: 'keyword', apply: '{% endfor -%}' },
      // variables
      { label: 'document.author', type: 'variable' },
      { label: 'document.content', type: 'variable' },
      { label: 'document.domain', type: 'variable' },
      { label: 'document.highlights', type: 'variable' },
      { label: 'document.language', type: 'variable' },
      { label: 'document.note', type: 'variable' },
      { label: 'document.summary', type: 'variable' },
      { label: 'document.tags', type: 'variable' },
      { label: 'document.title', type: 'variable' },
      // { label: 'user.tags', type: 'variable' },
      // subroutines
      { label: 'count_tokens', type: 'function' },
      { label: 'central_sentences', type: 'function' },
      { label: 'most_similar', type: 'function' },
      { label: 'truncate', type: 'function' },
    ],
  };
}

const jinja2 = langs.jinja2();
const jinja2completions = jinja2.data.of({
  autocomplete: completions,
});

const promptModelLabels = {
  [PromptModel.GPT_35_TURBO]: 'GPT-3.5 Turbo',
  [PromptModel.GPT_4]: 'GPT-4',
  [PromptModel.GPT_4_TURBO]: 'GPT-4 Turbo',
  [PromptModel.GPT_4o]: 'GPT-4o',
};

export const GhostreaderPromptsPageLegacy = () => {
  const latestArchivedDocumentDataQuery: MangoQuery = {
    selector: { triage_status: 'archive' },
    sort: [{ last_status_update: 'desc' }],
    limit: 1,
  };
  const [latestArchivedDocumentData] = useFind('documents', latestArchivedDocumentDataQuery);
  const [currentEditablePromptId, setCurrentEditablePromptId] = useState<string>();

  const overriddenPrompts = globalState((state) => state.persistent.settings.overriddenPrompts);
  const defaultPrompts = useDefaultPrompts();
  const prompts = mergePrompts(defaultPrompts, overriddenPrompts);

  const [showDiscardChangesDialog, setShowDiscardChangesDialog] = useState(false);

  const onPromptRender = async (prompt: Prompt) => {
    const controller = new AbortController();
    const toastId = createCancelableToast({
      content: 'Rendering prompt',
      onCancelClick: () => controller.abort(),
    });

    try {
      const payload = {
        template: {
          documentId: latestArchivedDocumentData?.[0]?.id,
          prompt: prompt.template,
        },
      };
      const response = await requestWithAuth(`${getServerBaseUrl()}/reader/api/render_prompt/`, {
        body: JSON.stringify(payload),
        credentials: 'include',
        method: 'POST',
        mode: 'cors',
      });
      if (response.ok) {
        const data = await response.json();
        if (data?.renderedPrompt) {
          await copyTextToClipboard(data.renderedPrompt, {
            successToastMessage: 'Copied prompt to clipboard',
          });
        }
      } else {
        createToast({
          category: 'warning',
          content: 'Failed to render prompt',
        });
      }
    } finally {
      toast.dismiss(toastId);
    }
  };
  const onUpdate = async (prompt: Prompt) => {
    setCurrentEditablePromptId(undefined);
    await updatePromptLegacy({
      ...prompt,
      responseAction: PromptResponseAction.AppendToNote,
      returnType: PromptReturnType.Text,
    });
  };
  const promptRefs = useRef<Map<PromptType, PromptDetailsHandle | null>>(new Map());

  const onResetAllPrompts = () => {
    for (const [, promptRef] of promptRefs.current) {
      promptRef?.reset();
    }
  };

  return (
    <AccountSettingsPage title="Ghostreader prompts">
      <div className={styles.headerWrapper}>
        <h1 className={styles.mainHeader}>
          <Link to="/preferences">Preferences /</Link> Ghostreader prompts
        </h1>
        <div className={styles.layoutDropdownWrapper}>
          <GhostreaderPromptsOptionsDropdown onResetAllPrompts={onResetAllPrompts} />
        </div>
      </div>

      <ul className={styles.ghostreaderPromptsList}>
        <li className={styles.categoryHeader}>
          <span>Automatic prompts</span>
          <small>Applies to newly saved documents automatically</small>
        </li>
        {Object.values(prompts).map((scope) => {
          return Object.values(scope)
            .sort((a, b) => (a.position > b.position ? 1 : a.position < b.position ? -1 : 0))
            .map((prompt) => {
              return (
                <PromptDetails
                  ref={(el) => {
                    promptRefs.current.set(prompt.type, el);
                  }}
                  key={promptId(prompt)}
                  prompt={prompt}
                  isEditable={promptId(prompt) === currentEditablePromptId}
                  onCancel={() => setShowDiscardChangesDialog(true)}
                  onEdit={setCurrentEditablePromptId}
                  onRender={onPromptRender}
                  onUpdate={onUpdate}
                />
              );
            });
        })}
      </ul>

      {showDiscardChangesDialog && (
        <Dialog
          id="discard-changes"
          title="Discard changes?"
          subtitle="Are you sure you want to discard your changes?"
          actionTitle="Discard"
          cancelTitle="Cancel"
          redActionButton
          action={() => {
            setCurrentEditablePromptId(undefined);
            setShowDiscardChangesDialog(false);
          }}
          cancelAction={() => setShowDiscardChangesDialog(false)}
        />
      )}
    </AccountSettingsPage>
  );
};

const getSelectedLabel = (selectedOption: PromptModel) => promptModelLabels[selectedOption];

const PromptDetails = forwardRef<PromptDetailsHandle, PromptDetailsProps>(
  ({ prompt, isEditable, onCancel, onEdit, onRender, onUpdate }: PromptDetailsProps, ref) => {
    const [isHovered, setIsHovered] = useState(false);
    const savedPromptModel = PromptModel[prompt.model];
    const savedPromptTemplate = prompt.template;
    const [currentPromptModel, setCurrentPromptModel] = useState(savedPromptModel);
    const [currentPromptTemplate, setCurrentPromptTemplate] = useState(savedPromptTemplate);
    const isDirty =
      currentPromptTemplate !== savedPromptTemplate || currentPromptModel !== savedPromptModel;
    useEffect(() => {
      setCurrentPromptModel(savedPromptModel);
    }, [savedPromptModel]);
    useEffect(() => {
      setCurrentPromptTemplate(savedPromptTemplate);
    }, [savedPromptTemplate]);
    const modelOptions: DropdownOption[] = [
      {
        checked: currentPromptModel === PromptModel.GPT_35_TURBO,
        name: getSelectedLabel(PromptModel.GPT_35_TURBO),
        description: 'Included with subscription',
        type: DropdownOptionType.Item,
        onSelect: () => setCurrentPromptModel(PromptModel.GPT_35_TURBO),
      },
      {
        checked: currentPromptModel === PromptModel.GPT_4,
        isDisabled: !canUseOpenAIModel(PromptModel.GPT_4),
        name: getSelectedLabel(PromptModel.GPT_4),
        description: 'Uses your own OpenAI API key',
        type: DropdownOptionType.Item,
        onSelect: () => {
          setShowConfirmExpensiveModelDialog(true);
          setPreliminaryModel(PromptModel.GPT_4);
        },
      },
      {
        checked: currentPromptModel === PromptModel.GPT_4_TURBO,
        isDisabled: !canUseOpenAIModel(PromptModel.GPT_4_TURBO),
        name: getSelectedLabel(PromptModel.GPT_4_TURBO),
        description: 'Uses your own OpenAI API key',
        type: DropdownOptionType.Item,
        onSelect: () => {
          setShowConfirmExpensiveModelDialog(true);
          setPreliminaryModel(PromptModel.GPT_4_TURBO);
        },
      },
      {
        checked: currentPromptModel === PromptModel.GPT_4o,
        isDisabled: !canUseOpenAIModel(PromptModel.GPT_4o),
        name: getSelectedLabel(PromptModel.GPT_4o),
        description: 'Uses your own OpenAI API key',
        type: DropdownOptionType.Item,
        onSelect: () => {
          setShowConfirmExpensiveModelDialog(true);
          setPreliminaryModel(PromptModel.GPT_4o);
        },
      },
    ];
    const [isOpen, setIsOpen] = useState(false);
    const [showConfirmExpensiveModelDialog, setShowConfirmExpensiveModelDialog] = useState(false);
    const [preliminaryModel, setPreliminaryModel] = useState<PromptModel>();

    const resetAndCancel = () => {
      setCurrentPromptTemplate(savedPromptTemplate);
      setCurrentPromptModel(savedPromptModel);
      onCancel();
    };

    const reset = () => {
      setCurrentPromptTemplate(savedPromptTemplate);
      setCurrentPromptModel(savedPromptModel);
    };

    useImperativeHandle(ref, () => ({ reset }));

    const currentPrompt: Prompt = useMemo(
      () => ({
        ...prompt,
        model: currentPromptModel,
        template: currentPromptTemplate,
      }),
      [currentPromptModel, currentPromptTemplate, prompt],
    );

    return (
      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
      <li
        className={`${styles.ghostreaderPromptData} ${isHovered && !isEditable ? styles.isHovered : ''}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className={styles.promptHeader}>
          <div className={styles.name}>
            {prompt.title}
            {prompt.description && <small>{prompt.description}</small>}
          </div>
          {!isEditable && (
            <div className={styles.actions}>
              <Button
                onClick={() => onEdit(promptId(prompt))}
                className={styles.visible}
                variant="unstyled"
              >
                Edit prompt
              </Button>
            </div>
          )}
        </div>
        {isEditable && (
          <div className={styles.promptEditor}>
            <CodeMirror
              basicSetup={{
                foldGutter: false,
                highlightActiveLine: false,
                highlightActiveLineGutter: false,
                lineNumbers: false,
                tabSize: 4,
              }}
              className={styles.promptInstruction}
              extensions={[jinja2, jinja2completions, EditorView.lineWrapping]}
              lang="jinja2"
              minHeight="192px"
              onChange={setCurrentPromptTemplate}
              placeholder="Enter your custom prompt"
              theme={readwiseTheme()}
              value={currentPromptTemplate}
            />
            <div className={styles.actionsGroup}>
              <Dropdown
                appendToDocumentBody
                isOpen={isOpen}
                options={modelOptions}
                setIsOpen={setIsOpen}
                contentAlignment="start"
                contentClassName={styles.dropdownContent}
                trigger={
                  <DropdownMenu.Trigger asChild>
                    <div className={styles.dropdownTrigger}>
                      {`AI Model: ${getSelectedLabel(currentPromptModel)}`}
                      <SelectIcon />
                    </div>
                  </DropdownMenu.Trigger>
                }
              />
              <div className={styles.actions}>
                <Button variant="secondary" onClick={resetAndCancel}>
                  Cancel
                </Button>
                <Tooltip content="Copy rendered prompt to clipboard" placement="top">
                  <Button variant="secondary" onClick={() => onRender(currentPrompt)}>
                    Render
                  </Button>
                </Tooltip>
                <Button variant="blue" disabled={!isDirty} onClick={() => onUpdate(currentPrompt)}>
                  Update
                </Button>
              </div>
            </div>
          </div>
        )}
        {showConfirmExpensiveModelDialog && (
          <Dialog
            id="use-own-openai-api-key"
            title="Use your own OpenAI API key?"
            subtitle="Are you sure you want to switch to a more expensive model at your own expense?"
            actionTitle="Confirm"
            cancelTitle="Cancel"
            blueSecondaryActionButton
            action={() => {
              setShowConfirmExpensiveModelDialog(false);
              setCurrentPromptModel(preliminaryModel!);
              setPreliminaryModel(undefined);
            }}
            cancelAction={() => {
              setShowConfirmExpensiveModelDialog(false);
              setCurrentPromptModel(PromptModel.GPT_35_TURBO);
              setPreliminaryModel(undefined);
            }}
          />
        )}
      </li>
    );
  },
);
PromptDetails.displayName = 'PromptDetailsLegacy';
